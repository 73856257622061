import React from 'react'
import SocialLinks from './sociallinks'

import logo from '../images/logo.png'

const IntroSubcomponent = ({ className, title, children }) => {
  return (
    <div className={`${className} mb3`}>
      <h2>{title}</h2>
      {children}
    </div>
  )
}

const Intro = () => {
  return (
    <div className="left pt3">
      <div className="content">
        <div>
          <div className="intro flex items-center mb3">
            <img alt="monkey coding" src={logo} className="avatar self-center mr2" />
            <div>
              <h1 className="mb1">Hi, I'm Naveen.</h1>
              <p className="mb0">Web Developer, Geek & a Unix Enthusiast.</p>
            </div>
          </div>
          <IntroSubcomponent className="products" title="I helped build products.">
            <a href="https://gitmate.io">gitmate-2</a>,&nbsp;
            <a href="https://gitlab.com/gitmate/open-source/IGitt">IGitt</a> and&nbsp;
            <a href="http://projects.coala.io">coala</a>.
          </IntroSubcomponent>
          <IntroSubcomponent className="companies" title="I worked with companies.">
            <a href="https://viperdev.io">ViperDev.io</a>,&nbsp;
            <a href="https://careers.wipro.com">Wipro</a> and&nbsp;
            <a href="https://gitmate.io">GitMate.io</a>
          </IntroSubcomponent>
          <IntroSubcomponent className="organizations" title="I'm a part of several organizations.">
            <a href="https://github.com/GitMateIO">GitMateIO</a>,&nbsp;
            <a href="https://github.com/summerofcode">Summer of Code</a>,&nbsp;
            <a href="https://coala.io">coala.io</a> and&nbsp;
            <a href="https://nitdgpos.github.io">NITDgpOS</a>. To name a few.
          </IntroSubcomponent>
          {/* <IntroSubcomponent className="links" title="Wanna hire me? Do reach out, please.">
              <a href="mailto:naveenkumarsangi@protonmail.com">Email</a>,&nbsp;
              <a href="https://gitlab.com/nkprince007">GitLab</a> and&nbsp;
              <a href="https://github.com/nkprince007">GitHub</a>
              </IntroSubcomponent> */}
          <IntroSubcomponent className="social" title="Feel free to ping me on any of these platforms.">
            <p className="icon-stack">{SocialLinks}</p>
          </IntroSubcomponent>
        </div>
      </div>
    </div>
  )
}

export default Intro
