import React from 'react';
import FontAwesome from 'react-fontawesome';

const SocialLink = ({ link, icon }) => {
  const iconName = icon.charAt(0).toUpperCase() + icon.slice(1)
  return (
    <a title={iconName} target="_blank" href={link} rel="noopener noreferrer">
      <FontAwesome name={icon} size="2x" />
    </a>
  );
}

const links = {
  "linkedin": "https://www.linkedin.com/in/naveenkumarsangi/",
  "medium": "https://medium.com/my-new-roots",
  "twitter": "https://twitter.com/nkprince007",
  "github": "https://github.com/nkprince007",
  "gitlab": "https://gitlab.com/nkprince007",
  "behance": "https://www.behance.net/nkprince00ff69",
  // "spotify": "https://open.spotify.com/user/nkprince007",
  // "instagram": "https://instagram.com/naveenkumarsangi",
};

const socialLinks = Object.keys(links).map((name) => {
  return <SocialLink key={name} icon={name} link={links[name]} />;
});

export default socialLinks;
