import React from 'react'

import Intro from '../components/intro'
import SEO from '../components/seo'
import { Container } from '../components/commons'

const IndexPage = () => (
  <>
    <SEO
      title="Home"
      keywords={['gatsby', 'portfolio', 'naveen kumar sangi', 'naveenkumarsangi']} />
    <Container id="main" className="main flex">
      <Intro />
    </Container>
  </>
)

export default IndexPage
